<template>
<div class="card">
  <div class="card-header bg-white">
    <h6 class="card-title font-weight-semibold">Form {{ $parent.objName }} Dietisien</h6>
  </div>
  <validation-observer ref="VFormDPDietisien">
    <b-form @submit.prevent="doSubmit()">
      <div class="card-body">
        <div class="row">
          <div class="col-12 mt-2">
            <div class="card mb-0">
              <div class="card-header bg_head_panel">
                <h6 class="card-title font-weight-semibold">Pendidikan Kesehatan yang Diberikan</h6>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-md-12">
                    <label>Diit</label>
                  </div>
                  <div class="col-md-4" v-for="(v, k) in mrPendidikanKesehatanGroups" :key="`pk-${k}`">
                    <div>
                      <div class="mb-2" v-for="(dt, kdt) in v" :key="`dt-${k}-${kdt}`">
                        <b-form-checkbox
                          :id="`mpkc-${k}-${kdt}`"
                          v-model="row.pendidikan_kesehatan"
                          :name="`mpkc-${k}-${kdt}`"
                          :key="`mpkc-${k}-${kdt}`"
                          :value="{ ...dt }"
                        >
                          {{ dt.label }}

                          <div class="input-group mt-2" v-if="dt.has_note && checkDataRow(dt.value, 'pendidikan_kesehatan')">
                            <b-form-input 
                              class="form-control" 
                              :placeholder="dt.placeholder" 
                              @change="updateValueCheckbox($event, dt.value, 'pendidikan_kesehatan', row.pendidikan_kesehatan)" 
                              :value="dt.note"
                              :formatter="dt.formatter ? Formatter[dt.formatter] : Formatter.default"
                            />
                            <div class="input-group-append" v-if="dt.suffix">
                              <span class="input-group-text">{{ dt.suffix }}</span>
                            </div>
                          </div>
                        </b-form-checkbox>

                      </div>
                    </div>
                  </div>
                </div>

                <VValidate
                  name="Pendidikan Kesehatan yang Diberikan"
                  :value="(row.pendidikan_kesehatan||[]).length > 0 ? true : null"
                  :rules="mrValidation.pendidikan_kesehatan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Nama &amp; Tanda Tangan Dietisien <span class="text-danger">*</span></label>
              <b-form-input placeholder="Nama Dietisien" v-model="row.nama_petugas" />
              <VValidate
                name="Nama"
                v-model="row.nama_petugas"
                :rules="mrValidation.nama_petugas"
              />

              <div class="signing-element mt-1" data-target-id="signature">
                <VueSignaturePad
                  class="sign-container"
                  width="100%"
                  height="160px"
                  ref="signature"
                  @onend="onEnd('signature')"
                />

                <VValidate
                  name="Tanda Tangan"
                  v-model="row.signature"
                  :rules="mrValidation.signature"
                />

                <div class="mt-2">
                  <button class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner" @click="undoTTD('signature','signature')">
                    <b><i class="icon-cross3"></i></b>
                    <span>Clear</span>
                  </button>
                  <button type="button" @click="output('signature','signature')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                      <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                  </button>
                </div>                 
              </div>

              
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="text-right">
          <button type="button" class="btn" @click="$router.push({ name: $parent.modulePage })">Kembali</button>
          <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </b-form>
  </validation-observer>
</div>
</template>

<script>
import Formatter from '@/libs/Formatter.js'
const _ = global._

export default {
  props: {},
  components: {},
  data() {
    return {
      formData: {
        pendidikan_kesehatan: []
      }
    }
  },

  mounted(){
    this.setSignature(this.row.signature)
  },

  methods: {
    updateValueCheckbox(value, keyValue, keyData, data){
      this.$set(this.formData, keyData, data.map(dt => {
        if(dt.value === keyValue) return { ...dt, note: value }

        return { ...dt }
      }))
    },
    setSignature(signature){
      setTimeout(() => { 
        if(signature) this.$refs['signature'].fromDataURL(signature)
      }, 1000)
    },    
    undoTTD(refs,field){
      this.$refs[refs].clearSignature()
      this.row[field] = null
    },
    output(refs,field) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
      }
    },

    checkDataRow(value, keyData){
      const indexKeyData = this.formData[keyData].findIndex(dt => dt.value === value)
      return indexKeyData >= 0
    },

    doSubmit() {
      this.$refs['VFormDPDietisien'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = { ...this.row, ...this.formData }
            this.$parent.doSubmit({
              ...payload,
              type: 'update-discharge-planning'
            })
          }
        })
      })
    }
  },
  
  computed: {
    row(){
      return this.$parent.row
    },    

    mrPendidikanKesehatanGroups(){
      return _.chunk(this.$parent.mrPendidikanKesehatan, 2)
    },

    mrValidation(){
      return this.$parent.mrValidation
    },

    Formatter(){
      return Formatter
    }
  },


  watch: {
    'row.pendidikan_kesehatan'(v){
      const data = JSON.parse(JSON.stringify([...v]))
      this.$set(this.formData, 'pendidikan_kesehatan', data)
    },
    'row.signature'(v){
      this.setSignature(v)
    }
  }
}
</script>
